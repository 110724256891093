import React, { useEffect, useState } from 'react';
import { MdArrowRightAlt } from 'react-icons/md';
import { collection, addDoc, getDoc,doc } from 'firebase/firestore'; // Import addDoc and collection
import { db } from '../firebase/firestore';
import { useUser } from '../context/userContext';
import { IoCheckmarkCircleSharp, IoClose } from 'react-icons/io5';
import { QRCodeCanvas } from 'qrcode.react'; // Import QRCodeCanvas instead of default QRCode

const miningPowers = [
  {
    title: "2x mining rig power",
    capacity: 0.018, // Updated to 0.018 USDT per hour
    capacity365: 157.68, // Total for 365 days
    price: 25, // 25 USDT
    xx: '2x'
  },
  {
    title: "5x mining rig power",
    capacity: 0.045, // Updated to 0.045 USDT per hour
    capacity365: 394.20, // Total for 365 days
    price: 65, // 65 USDT
    xx: '5x'
  },
  {
    title: "10x mining rig power",
    capacity: 0.09, // Updated to 0.09 USDT per hour
    capacity365: 788.40, // Total for 365 days
    price: 130, // 130 USDT
    xx: '10x'
  },
  {
    title: "30x mining rig power",
    capacity: 0.27, // Updated to 0.27 USDT per hour
    capacity365: 2365.20, // Total for 365 days
    price: 390, // 390 USDT
    xx: '30x'
  },
  {
    title: "50x mining rig power",
    capacity: 0.45, // Updated to 0.45 USDT per hour
    capacity365: 3942.00, // Total for 365 days
    price: 650, // 650 USDT
    xx: '50x'
  },
  {
    title: "100x mining rig power",
    capacity: 0.90, // Updated to 0.90 USDT per hour
    capacity365: 7884.00, // Total for 365 days
    price: 1450, // 1450 USDT
    xx: '100x'
  },
  {
    title: "300x mining rig power",
    capacity: 2.70, // Updated to 2.70 USDT per hour
    capacity365: 23652.00, // Total for 365 days
    price: 4500, // 4500 USDT
    xx: '300x'
  },
  {
    title: "500x mining rig power",
    capacity: 4.50, // Updated to 4.50 USDT per hour
    capacity365: 39420.00, // Total for 365 days
    price: 8000, // 8000 USDT
    xx: '500x'
  },
  {
    title: "1000x mining rig power",
    capacity: 9.00, // Updated to 9.00 USDT per hour
    capacity365: 78840.00, // Total for 365 days
    price: 17000, // 17000 USDT
    xx: '1000x'
  },
];

const BoostFarm = () => {
  const { id, username } = useUser();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  const [selectedPower, setSelectedPower] = useState(null);
  const [transactionHash, setTransactionHash] = useState("");
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [congratsMessage, setCongratsMessage] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [openBoost, setOpenBoost] = useState(false);
  const [congrats, setCongrats] = useState(false);
  const [usdtAddress, setUsdtAddress] = useState(""); // State for USDT address
  const [isCopied, setIsCopied] = useState(false); // To show copy status

  // Fetch the USDT TRC20 address from Firestore
  useEffect(() => {
    const fetchUsdtAddress = async () => {
      try {
        const docRef = doc(db, 'transactionSettings', 'config');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data && data.address) {
            setUsdtAddress(data.address); // Set the fetched address
          } else {
            setMessage("USDT address not found.");
            setMessageColor("red");
          }
        } else {
          setMessage("Transaction settings not found.");
          setMessageColor("red");
        }
      } catch (err) {
        console.error("Error fetching USDT address:", err);
        setMessage("Failed to fetch USDT address. Please try again later.");
        setMessageColor("red");
      }
    };

    fetchUsdtAddress();
  }, []);

  // Function to copy the USDT address to the clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(usdtAddress)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset copy status after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
  };

  const handleClick = async () => {
    setButtonDisabled(true);

    if (!selectedPower || !transactionHash) {
      setMessage("Please select a power and enter the transaction hash.");
      setMessageColor("red");
      setButtonDisabled(false);
      return;
    }

    const date = new Date();

    try {
      // Save the transaction request in a new "transactionRequests" collection
      const transactionRequestRef = collection(db, 'transactionRequests');
      await addDoc(transactionRequestRef, {
        userId: id,
        username: username,
        miningPower: selectedPower.capacity,
        hash: transactionHash,
        price: selectedPower.price,
        date: date.toLocaleDateString(),
        time: date.toLocaleTimeString(),
        status: 'pending', // Mark request as pending
      });

      setMessage("Transaction saved! Waiting for admin approval.");
      setMessageColor("green");

      setCongratsMessage(
        <div className="w-full flex justify-center flex-col items-center space-y-3">
          <IoCheckmarkCircleSharp size={32} className="text-accent" />
          <p className="font-medium text-center">Request Submitted!</p>
          <span className="font-medium text-[20px] text-[#ffffff] pt-2 pb-2 flex flex-col justify-center w-full text-center items-center space-x-1">
            <span className='flex items-center justify-center space-x-[2px] text-[18px]'>
              <span className='w-[20px] h-[20px] fanbg border-[#616161] border-[1px] flex justify-center rounded-full items-center text-center relative'>
                <img src='/fan.webp' alt='dscfd' className='w-[16px] h-[16px]' />
              </span>
              <span className="text-accent">+{selectedPower.capacity365} USDT</span>
            </span>
            <span>Mining Rig Upgrade Request Submitted.</span>
          </span>
          <p className="pb-6 text-[15px] w-full text-center">
            Your request is now pending admin approval. You will be notified once confirmed.
          </p>
        </div>
      );

      setShowCongratsModal(true);
      setOpenUpgrade(false);
      setCongrats(true);
      setTimeout(() => {
        setCongrats(false);
      }, 3000);

    } catch (err) {
      console.error('Error saving transaction:', err);
      setMessage("Failed to save the transaction. Please try again.");
      setMessageColor("red");
    } finally {
      setButtonDisabled(false);
    }
  };

  const openUpgrader = (power) => {
    setSelectedPower(power);
    setOpenUpgrade(true);
    setOpenBoost(false);
  };

  const closeUpgrader = () => {
    setOpenBoost(true);
    setOpenUpgrade(false);
    setMessage("");
  };

  return (
    <>
      <div className="w-full flex items-center justify-center px-3 pt-16">
        <button 
          onClick={() => setOpenBoost(true)}
          className="w-full bg-btn4 text-[#000] py-3 px-4 text-center font-semibold rounded-[8px] flex items-center justify-center space-x-1">
          <span> Boost Mining</span>
          <MdArrowRightAlt size={16} className="mt-[2px]" />
        </button>
      </div>

      {openBoost && (
        <>
          <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
            <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
              <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-14 pb-10">
                <button
                  onClick={() => setOpenBoost(false)}
                  className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]">
                  <IoClose size={20} className="text-[#9995a4]" />
                </button>

                <div className="w-full flex justify-center flex-col items-center">
                  <h3 className="font-semibold text-center text-[20px] pt-2 pb-4">
                    Increase mining power
                  </h3>
                  <div className='w-full flex flex-col space-y-2'>
                    {miningPowers.map((power, index) => (
                      <button key={index} onClick={() => openUpgrader(power)}
                        className='w-full bg-cards py-4 px-4 rounded-[8px] flex justify-between items-center'>
                        <span className='text-[14px] font-medium'>{power.title}</span>
                        <span className='text-[13px] font-semibold text-accent'>
                          +{power.capacity365} USDT
                        </span>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {openUpgrade && selectedPower && (
        <>
          <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
            <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
              <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-20 pb-10">
                <button
                  onClick={closeUpgrader}
                  className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]">
                  <IoClose size={20} className="text-[#9995a4]" />
                </button>

                <div className="w-full flex justify-center flex-col items-center">
                  <h3 className="font-semibold text-center text-[20px] pt-2">
                    {selectedPower.title}
                  </h3>
                  <p className="pb-6 text-primary text-[14px] px-4 text-center">
                    Boost your mining rig capacity to earn {selectedPower.capacity} USDT profit per hour, totaling {selectedPower.capacity365} USDT over 365 days.
                  </p>

                  <div className='w-full flex justify-center items-center space-x-2 pb-3'>
                    <div className="w-[45%] bg-cards text-[12px] rounded-[6px] p-2 text-primary flex items-center justify-center space-x-1 font-semibold text-center">
                      <span> Price:</span> <span className='pl-1'><img src='usdt.png' alt='usdt' className='w-[12px] h-[12px]'/></span> <span>{selectedPower.price} USDT</span>
                    </div>
                    <div className="w-[45%] bg-cards text-[12px] rounded-[6px] p-2 text-primary flex items-center justify-center space-x-1 font-semibold text-center">
                      <span> Profit:</span> <span className='pl-1'><img src='dogs.webp' alt='profit' className='w-[10px] h-[10px]'/></span> <span className='text-green-500 '>{selectedPower.capacity365} USDT</span>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <QRCodeCanvas value={usdtAddress} size={128} includeMargin={true} />
                  </div>
                  <p className="pt-4 text-primary text-[14px] text-center">Send USDT to the above address</p> 
                  <p className="pt-2 text-primary text-[14px] text-center cursor-pointer" onClick={copyToClipboard}>
                    {usdtAddress} {isCopied && <span className="text-green-500 ml-2">(Copied!)</span>}
                  </p>

                  <div className='w-full flex flex-col space-y-4 pt-4'>
                    <label className="text-primary text-[12px]">Enter Transaction Hash:</label>
                    <input
                      type="text"
                      value={transactionHash}
                      onChange={(e) => setTransactionHash(e.target.value)}
                      className="w-full p-3 rounded-lg border border-gray-300 text-black"
                      placeholder="Transaction Hash"
                    />
                  </div>
                </div>

                <div className="w-full flex justify-center items-center flex-col space-y-2 pb-7">
                  <button
                    onClick={handleClick}
                    className={`${buttonDisabled ? 'bg-[#5A4420]' : 'bg-btn'} text-[#000] w-full py-[18px] px-6 text-nowrap flex items-center justify-center text-center rounded-[12px] font-semibold text-[17px]`}
                    disabled={buttonDisabled}
                  >
                    Submit Payment Hash
                  </button>
                </div>

                {message && (
                  <p className='w-full text-center text-[13px]' style={{ color: messageColor, marginTop: "10px" }}>
                    {message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className='w-full absolute top-[50px] flex justify-center z-50 pointer-events-none select-none'>
        {congrats ? (<img src='/congrats.gif' alt="congrats" className="w-[80%]" />) : (<></>)}
      </div>

      <div
        className={`${showCongratsModal === true ? "visible" : "invisible"} fixed top-[-12px] bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}
      >
        <div className={`${showCongratsModal === true ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"} w-full bg-modal relative rounded-[16px] flex flex-col justify-center p-8`}>
          {congratsMessage}

          <div className="w-full flex justify-center">
            <button
              onClick={() => setShowCongratsModal(false)}
              className={`bg-btn4 w-full py-[16px] px-6 flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]`}
            >
              Continue Mining
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoostFarm;