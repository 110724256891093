import React, { useEffect, useState } from 'react';
import Animate from '../Components/Animate';
import { useUser } from '../context/userContext';
import { Address } from '../Components/Address';
import Exchanges from '../Components/Exchanges';
import { RiExchangeDollarFill } from "react-icons/ri";
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { CiNoWaitingSign } from 'react-icons/ci';
import { db } from '../firebase/firestore';
import { addDoc, collection, doc, updateDoc, getDocs, query, where } from 'firebase/firestore';
import { IoCheckmarkCircleSharp, IoClose } from 'react-icons/io5';
import { MdArrowRightAlt } from 'react-icons/md';

const Airdrop = () => {
  const { balance, selectedExchange, id, setBalance } = useUser();
  const [showExchange, setShowExchange] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false); // To show the success popup
  const [walletAddress, setWalletAddress] = useState(''); // Store the wallet address
  const [showWithdrawPopup, setShowWithdrawPopup] = useState(false); // For withdrawal confirmation popup
  const [withdrawRequests, setWithdrawRequests] = useState([]); // To store user's withdrawal requests
  const MIN_WITHDRAW_AMOUNT = 30; // Set minimum withdraw amount to $30
  const WITHDRAWAL_FEE = 2; // Add a $2 fee

  // Fetch withdrawal requests
  useEffect(() => {
    const fetchWithdrawRequests = async () => {
      try {
        const q = query(collection(db, 'withdraw_requests'), where('userId', '==', id));
        const querySnapshot = await getDocs(q);
        const requests = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setWithdrawRequests(requests);
      } catch (error) {
        console.error("Error fetching withdrawal requests: ", error);
      }
    };

    fetchWithdrawRequests();
  }, [id]);

  const handleWithdraw = async () => {
    const totalAmount = balance - WITHDRAWAL_FEE;
    if (balance < MIN_WITHDRAW_AMOUNT) {
      setErrorMessage(`Your balance is below $${MIN_WITHDRAW_AMOUNT}. You can't withdraw because the minimum withdrawal amount is $${MIN_WITHDRAW_AMOUNT}.`);
      return;
    }
  
    // Clear the error message if the balance is sufficient
    setErrorMessage('');
    // Open the withdrawal confirmation popup
    setShowWithdrawPopup(true);
  };

  const submitWithdrawRequest = async () => {
    const totalAmount = balance - WITHDRAWAL_FEE;

    setWithdrawLoading(true);
    setErrorMessage('');

    try {
      const withdrawData = {
        userId: id,
        balance: totalAmount, // Deduct the $2 fee
        walletAddress: walletAddress, // Include the entered wallet address
        requestedAt: new Date(),
        status: 'pending',
      };

      // Save withdrawal request to Firestore
      const docRef = await addDoc(collection(db, 'withdraw_requests'), withdrawData);

      if (docRef.id) {
        // If the request was successful, reset the user's balance in Firestore
        const userRef = doc(db, 'telegramUsers', id.toString());
        await updateDoc(userRef, { balance: 0, totalBalance: 0 });

        // Update balance in the frontend state
        setBalance(0);

        // Show success popup
        setShowPopup(true);

        // Fetch updated withdrawal requests
        const q = query(collection(db, 'withdraw_requests'), where('userId', '==', id));
        const querySnapshot = await getDocs(q);
        const requests = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setWithdrawRequests(requests);
      }
    } catch (error) {
      console.error('Error submitting withdrawal request:', error);
      setErrorMessage('Failed to submit withdrawal request.');
    } finally {
      setWithdrawLoading(false);
      setShowWithdrawPopup(false); // Close the withdrawal popup after submission
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const openExchange = () => {
    setShowExchange(true);
  };

  return (
    <Animate>
      <div className='w-full flex justify-center items-center flex-col space-y-3'>
        <div className='w-full flex items-center justify-center pt-8 pb-3'>
          <img alt="daxy" src="/prem.svg" className="w-[100px]" />
        </div>

        <div className='w-full flex items-center justify-center pb-1'>
          <Address />
        </div>

        {errorMessage && (
  <div className="w-full text-center text-red-500 py-2">
    {errorMessage}
  </div>
)}

<div className="w-full flex justify-center pt-4">
  <button
    onClick={handleWithdraw}
    className={`${withdrawLoading ? 'bg-gray-400' : balance < MIN_WITHDRAW_AMOUNT ? 'bg-gray-400' : 'bg-green-500'} text-white py-3 px-6 rounded-[12px] font-medium`}
    disabled={withdrawLoading || balance < MIN_WITHDRAW_AMOUNT}
    title={balance < MIN_WITHDRAW_AMOUNT ? `Your balance is below $${MIN_WITHDRAW_AMOUNT}. You can't withdraw at this point.` : ''}
  >
    {withdrawLoading ? 'Processing...' : balance < MIN_WITHDRAW_AMOUNT ? `Your Balance is below $${MIN_WITHDRAW_AMOUNT}. restricted withdraw.` : 'Withdraw'}
  </button>
</div>

        {/* Main section */}
        <div className='w-full relative h-screen bg-[rgb(146,139,122)] rounded-tl-[40px] rounded-tr-[40px]'>
          <div className='w-full h-screen homescreen rounded-tl-[40px] rounded-tr-[40px] mt-[2px] px-5'>
            <div id="refer" className='w-full rounded-[16px] flex flex-col scroller h-[70vh] overflow-y-auto pb-[250px]'>

              <div className='w-full flex flex-col text-center justify-center items-center pt-6'>
                <h1 className='font-semibold text-[20px]'>Airdrop Qualifiers</h1>
                <p className='text-[14px] text-[#c6c6c6] leading-[24px] px-3 pb-8'>
                  Listing and launching soon! All activities will be listed below. Follow us on social platforms for upcoming updates.
                </p>
              </div>

              <div className='w-full flex flex-col space-y-[10px]'>

                <button 
                  onClick={openExchange}
                  className={`w-full bg-[#23221f] text-[14px] rounded-[10px] px-4 py-4 space-x-2 flex items-center justify-between`}
                >
                  <div className='flex items-center space-x-2 justify-start w-[80%]'>
                    {selectedExchange.id === 'selectex' ? (
                      <span className="flex items-center justify-center mt-[1px]">
                        <RiExchangeDollarFill size={34} className={``} />
                      </span>
                    ) : (
                      <span className="flex items-center justify-center mt-[1px]">
                        <img src={selectedExchange.icon} alt={selectedExchange.title} className={`w-[34px] h-[34px] rounded-full`} />
                      </span>
                    )}
                    <div className='flex flex-col text-left'>
                      <h2 className='flex flex-1 font-medium'>Choose airdrop exchange</h2>
                      <div className='text-[13px] font-normal'>
                        {selectedExchange.id === 'selectex' ? 'None' : selectedExchange.name}
                      </div>
                    </div>
                  </div>
                  <MdOutlineKeyboardArrowRight size={24} className={`text-[#959595]`} />
                </button>

              </div>

              {/* Add withdrawal request list with a more structured design */}
              <div className='w-full flex flex-col space-y-2 mt-4'>
                <h3 className='text-center font-semibold text-lg'>Your Withdrawal Requests</h3>
                {withdrawRequests.length === 0 ? (
                  <p className="text-center text-gray-500">No withdrawal requests found.</p>
                ) : (
                  withdrawRequests.map((request) => (
                    <div key={request.id} className="w-full bg-[#23221f] p-4 rounded-md shadow-md flex flex-col space-y-2">
                      <p className='text-sm'><strong>Date:</strong> {new Date(request.requestedAt.seconds * 1000).toLocaleDateString()}</p>
                      <p className='text-sm'><strong>Amount:</strong> ${request.balance.toFixed(2)}</p>
                      <p className='text-sm'><strong>Status:</strong> 
                        <span className={`ml-2 px-2 py-1 rounded-md ${
                          request.status === 'approved' ? 'bg-green-500 text-white' :
                          request.status === 'pending' ? 'bg-yellow-500 text-white' :
                          'bg-red-500 text-white'
                        }`}>
                          {request.status}
                        </span>
                      </p>
                    </div>
                  ))
                )}
              </div>

            </div>
          </div>
        </div>

        {showWithdrawPopup && (
  <div className="fixed flex bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#303030c4] flex-col justify-end items-center">
    <div className="w-full bg-divider shadowtop rounded-tl-[40px] rounded-tr-[40px] relative flex flex-col ease-in duration-300 transition-all justify-center">
      <div className="w-full flex taskbg rounded-tl-[40px] rounded-tr-[40px] mt-[2px] justify-center relative flex-col items-center space-y-3 p-4 pt-14 pb-10">
        <button
          onClick={() => setShowWithdrawPopup(false)}
          className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#383838] absolute right-6 top-4 text-center font-medium text-[16px]"
        >
          <IoClose size={20} className="text-[#9995a4]" />
        </button>

        <div className="w-full flex justify-center flex-col items-center">
          <div className='w-[60px] h-[60px] fanbg border-[#616161] border-[2px] flex justify-center rounded-full items-center text-center relative'>
            
            <div className='absolute z-10 bg-[#3f2900] border-[1px] border-[#8b8b8b] rounded-full h-[24px] w-[24px] flex justify-center items-center'>
              
            </div>
          </div>
          <h3 className="font-semibold text-center text-[20px] pt-2 pb-4">
            Confirm Withdrawal
          </h3>
          <p className="text-sm text-gray-400">A $2 fee will be deducted from your total withdrawal. Please enter your USDT TRC20 wallet address.</p>

          <input
            type="text"
            placeholder="USDT TRC20 Wallet Address"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            className="w-full px-4 py-2 bg-white text-black rounded-[8px] focus:outline-none"
          />

          <p className="text-sm text-gray-500 mt-2">Note: Withdrawal amount is in USDT. Ensure the wallet address is correct.</p>

          <div className='w-full flex flex-col space-y-2 mt-4'>
            <button
              onClick={submitWithdrawRequest}
              className='w-full bg-green-500 py-4 px-4 rounded-[8px] flex justify-between items-center'
            >
              <span className='flex flex-1 space-x-[6px] items-center'>
                <span className='text-[14px] font-medium'>
                  Submit Withdrawal
                </span>
              </span>
            </button>

            <button
              onClick={() => setShowWithdrawPopup(false)}
              className='w-full bg-gray-400 py-4 px-4 rounded-[8px] flex justify-between items-center'
            >
              <span className='flex flex-1 space-x-[6px] items-center'>
                <span className='text-[14px] font-medium'>
                  Cancel
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
)}


        {/* Success Popup */}
        {showPopup && (
          <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-md shadow-lg w-[90%] max-w-[400px]">
              <h2 className="text-lg font-semibold mb-2">Withdrawal Request Submitted</h2>
              <p className="mb-4">Your withdrawal request has been successfully submitted.</p>
              <button
                onClick={closePopup}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        )}

        <Exchanges showExchange={showExchange} setShowExchange={setShowExchange} />
      </div>
    </Animate>
  );
};

export default Airdrop;
