import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, query, where, doc, getDoc, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/firestore'; // Adjust the path as necessary
import Pagination from '../../Components/Pagination'; // Assume you have a pagination component

const Payments = () => {
  const [transactionRequests, setTransactionRequests] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage] = useState(10); // Change to set items per page
  const [loading, setLoading] = useState(true);

  // Fetch username from the telegramUsers collection
  const fetchUserName = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'telegramUsers', userId));
      if (userDoc.exists()) {
        return userDoc.data().username || 'Unknown';
      } else {
        return 'Unknown';
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      return 'Unknown';
    }
  };

  // Fetch transaction requests and add username
  useEffect(() => {
    const fetchTransactionRequests = async () => {
      setLoading(true);
      try {
        let q = query(collection(db, 'transactionRequests'), orderBy('status', 'asc')); // Ordering by status
        const querySnapshot = await getDocs(q);
        const requests = await Promise.all(querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const username = await fetchUserName(data.userId); // Fetch the username
          return { id: doc.id, username, ...data };
        }));
        setTransactionRequests(requests);
      } catch (error) {
        console.error("Error fetching transaction requests: ", error);
      }
      setLoading(false);
    };

    fetchTransactionRequests();
  }, []);

  // Filter transaction requests based on the selected status
  const filteredRequests = transactionRequests.filter((request) => {
    if (filterStatus === 'all') return true;
    return request.status === filterStatus;
  });

  // Pagination logic
  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const currentRequests = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest);

  // Handle status change (approve/decline)
  const handleStatusChange = async (id, status, userId, miningPower) => {
    try {
      // Update the transaction request status
      const requestRef = doc(db, 'transactionRequests', id);
      await updateDoc(requestRef, { status });

      // If the status is approved, update the miningPower in the telegramUsers collection
      if (status === 'approved') {
        const userRef = doc(db, 'telegramUsers', userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          await updateDoc(userRef, {
            miningPower: miningPower,
          });
        }
      }

      // Update the UI state to reflect the changes
      setTransactionRequests(prevRequests =>
        prevRequests.map(req => (req.id === id ? { ...req, status } : req))
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="p-6 bg-gray-900 text-white min-h-screen">
      <h1 className="text-2xl font-bold mb-6 text-center">Transaction Requests</h1>

      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center">
          <label htmlFor="filter" className="mr-3 text-lg">Filter by Status:</label>
          <select
            id="filter"
            value={filterStatus}
            onChange={e => setFilterStatus(e.target.value)}
            className="p-2 bg-black text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            <option value="all">All</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="declined">Declined</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="text-center text-xl">Loading...</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="table-auto w-full text-left bg-gray-800 rounded-lg shadow-lg">
              <thead className="bg-gray-700 text-gray-200">
                <tr>
                  <th className="px-4 py-3">User</th>
                  <th className="px-4 py-3">Hash</th>
                  <th className="px-4 py-3">Request Date</th>
                  <th className="px-4 py-3">Mining Power</th>
                  <th className="px-4 py-3">Mining Price</th>
                  <th className="px-4 py-3">Status</th>
                  <th className="px-4 py-3">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-300">
                {currentRequests.map(request => (
                  <tr key={request.id} className="border-b border-gray-700">
                    <td className="px-4 py-4">{request.username || 'N/A'}</td>
                    <td className="px-4 py-4">{request.hash || 'N/A'}</td>
                    <td className="px-4 py-4">{request.date} {request.time}</td>
                    <td className="px-4 py-4">{request.miningPower} PPH</td>
                    <td className="px-4 py-4">{request.price} USDT</td>
                    <td className="px-4 py-4">{request.status}</td>
                    <td className="px-4 py-4">
                      {request.status === 'pending' && (
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleStatusChange(request.id, 'approved', request.userId, request.miningPower)}
                            className="bg-green-600 hover:bg-green-700 text-white px-3 py-1 rounded shadow"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() => handleStatusChange(request.id, 'declined', request.userId, request.miningPower)}
                            className="bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded shadow"
                          >
                            Decline
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-6">
          <Pagination
  requestsPerPage={requestsPerPage}
  totalRequests={filteredRequests.length} 
  currentPage={currentPage}
  paginate={setCurrentPage}
/>

          </div>
        </>
      )}
    </div>
  );
};

export default Payments;
