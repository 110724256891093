import React, { useEffect, useRef, useState } from 'react';
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase/firestore';
import { useUser } from '../context/userContext';

export const Address = () => {
  const { id, setWalletAddress, setIsAddressSaved } = useUser();
  const [disconnect, setDisconnect] = useState(false);

  const [openInfoTwo, setOpenInfoTwo] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [usdtWallet, setUsdtWallet] = useState(''); // To store USDT TRC20 address
  const [isSaving, setIsSaving] = useState(false); // To show save loading
  const [saveSuccess, setSaveSuccess] = useState(false); // To show success message
  const [savedWalletAddress, setSavedWalletAddress] = useState(''); // To store and display saved wallet

  const infoRefTwo = useRef(null);
  const infoRef = useRef(null);

  const handleClickOutside = (event) => {
    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setOpenInfoTwo(false);
    }
    if (infoRef.current && !infoRef.current.contains(event.target)) {
      setOpenInfo(false);
    }
  };

  useEffect(() => {
    if (openInfoTwo || openInfo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openInfoTwo, openInfo]);

  // Fetch saved USDT wallet address when the component mounts
  useEffect(() => {
    const fetchSavedWallet = async () => {
      try {
        const userRef = doc(db, 'telegramUsers', id.toString());
        const userSnap = await getDoc(userRef);

        if (userSnap.exists() && userSnap.data().usdtWalletAddress) {
          const savedAddress = userSnap.data().usdtWalletAddress;
          setSavedWalletAddress(savedAddress);
          setWalletAddress(savedAddress);
        }
      } catch (error) {
        console.error('Error fetching saved wallet address:', error);
      }
    };

    fetchSavedWallet();
  }, [id, setWalletAddress]);

  const saveAddressToFirestore = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    setIsSaving(true);
    try {
      await updateDoc(userRef, {
        usdtWalletAddress: usdtWallet, // Save USDT wallet address
      });
      setSavedWalletAddress(usdtWallet); // Set this as the user's saved wallet address
      setWalletAddress(usdtWallet); // Update context state
      setIsAddressSaved(true); // Set address saved to true
      setSaveSuccess(true); // Show confirmation message

      // Automatically close the popup after 2 seconds
      setTimeout(() => {
        setOpenInfoTwo(false); // Close the popup
        setSaveSuccess(false); // Hide confirmation message
        setUsdtWallet(''); // Clear the input field
      }, 2000); // 2-second delay
    } catch (error) {
      console.error('Error saving wallet address:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDisconnect = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      // Remove the saved USDT wallet address from Firestore
      await updateDoc(userRef, {
        usdtWalletAddress: '',
      });
      setSavedWalletAddress(''); // Clear the state
      setWalletAddress(''); // Reset context
      setIsAddressSaved(false); // Mark as not connected
      setOpenInfo(false); // Close the disconnect popup
      console.log('Wallet address disconnected successfully');
    } catch (error) {
      console.error('Error disconnecting wallet address:', error);
    }
  };

  return (
    <>
      <div className="w-full rounded-[15px] flex flex-col justify-center items-center relative">
        {savedWalletAddress !== '' ? (
          <div className="w-full flex flex-col items-center">
            <button
              onClick={() => setOpenInfo(true)}
              className={`bg-[#a4a4a424] flex h-full w-[70%] rounded-full items-center py-[10px] px-3 relative space-x-1`}
            >
              <img
                src='/wallet.webp'
                alt="connect"
                className="w-[16px] -mt-[2px]"
              />
              <div className="text-[13px] small-text2 text-left pr-3 text-nowrap text-white flex flex-1 flex-col">
                <h4 className='font-semibold text-[#d1d1d1] line-clamp-1 break-all text-wrap'>
                  {savedWalletAddress}
                </h4>
              </div>
              <IoCheckmarkCircle size={20} className='text-[#40863d]' />
            </button>

            {/* Disconnect Button */}
            <button
              onClick={handleDisconnect}
              className='mt-4 bg-red-500 text-white py-2 px-6 rounded-full text-sm'
            >
              Disconnect Wallet
            </button>
          </div>
        ) : (
          <button
            onClick={() => setOpenInfoTwo(true)}
            className={`bg-[#319cdf] flex h-full w-[70%] rounded-full items-center justify-center py-[13px] px-4 relative space-x-1`}
          >
            <img
              src='/wallet.webp'
              alt="connect"
              className="w-[16px] -mt-[1px]"
            />
            <div className="text-[13px] small-text2 font-medium text-left text-nowrap text-white flex flex-col">
              Connect your wallet
            </div>
            <MdOutlineKeyboardArrowRight size={20} className='text-[#fff] mb-[-1px]' />
          </button>
        )}
      </div>

      <div className={`${
        openInfoTwo === true ? "visible" : "invisible"
      } fixed bottom-0 left-0 z-40 right-0 h-[100vh] bg-[#00000042] flex justify-center items-center backdrop-blur-[6px] px-4`}>
        <div ref={infoRefTwo} className={`${
          openInfoTwo === true ? "opacity-100 mt-0 ease-in duration-300" : "opacity-0 mt-[100px]"
        } w-full bg-modal absolute bottom-0 left-0 right-0 rounded-tr-[40px] rounded-tl-[40px] flex flex-col justify-center p-8`}>
          <div className="w-fit flex justify-center absolute right-6 top-6">
            <button
              onClick={() => setOpenInfoTwo(false)}
              className="w-fit flex items-center justify-center text-center rounded-[12px] font-medium text-[16px]"
            >
              <IoCloseCircle size={32} className="text-[#8f8f8f]" />
            </button>
          </div>
          <div className="w-full flex justify-center flex-col items-center space-y-3 pt-6 pb-32">
            <div className="w-full items-center justify-center flex flex-col space-y-2">
              <span className="w-[72px] flex items-center">
                <img
                  src='/wallet.webp'
                  alt="connect"
                  className="w-full"
                />
              </span>
              <h3 className='font-semibold text-[22px] w-full text-center'>
                Enter your USDT TRC20 wallet address
              </h3>
              <p className="pb-6 text-[#bfbfbf] text-[14px] w-full text-center">
                Input your USDT TRC20 wallet address for withdrawals and airdrops.
              </p>
              <input
                type="text"
                value={usdtWallet}
                onChange={(e) => setUsdtWallet(e.target.value)}
                className="w-full px-4 py-2 text-black bg-white rounded-[8px] focus:outline-none"
                placeholder="Enter USDT TRC20 Wallet Address"
              />
              <button
                onClick={saveAddressToFirestore}
                className={`mt-4 w-full bg-blue-500 text-white py-2 rounded-[8px] font-medium ${isSaving ? 'cursor-not-allowed opacity-50' : ''}`}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save Address'}
              </button>
              {saveSuccess && (
                <p className="mt-4 text-green-500 font-semibold">
                  Address saved successfully!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
